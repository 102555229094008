import { useAppSelector } from '@/hooks/redux';
import { getIconByCurrency } from '@/utils/helpers';
import { Text } from '@purposeinplay/core-v2';
import React from 'react';
import { TableCell } from '@purposeinplay/core-v2';
import { cn } from '@/utils/style';

const WinAmountCell = React.memo(
  ({
    profitAmount,
    formattedWinAmount,
    currency,
    fiatProfit,
    className,
  }: {
    profitAmount: number;
    formattedWinAmount: string;
    currency: string;
    fiatProfit: React.ReactNode;
    className?: string;
  }) => {
    const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
    return (
      <TableCell className={cn([className && className])} variant="condensed">
        <div className="md:min-w-40">
          {!viewFiat ? (
            <div className="flex flex-row items-center justify-end">
              <Text
                size="sm"
                className={cn([
                  'mr-1 leading-4 text-text-subdued',
                  profitAmount > 0 && `text-base-primary`,
                ])}
              >
                {profitAmount > 0
                  ? `+${formattedWinAmount.split(' ')[0]}`
                  : formattedWinAmount.split(' ')[0]}
              </Text>
              {getIconByCurrency(currency)}
            </div>
          ) : (
            <span className="flex items-center justify-end">
              <span className="mr-1.5">{fiatProfit}</span>
              <span>{getIconByCurrency(currency)}</span>
            </span>
          )}
        </div>
      </TableCell>
    );
  },
);

WinAmountCell.displayName = 'WinAmountCell';

export default WinAmountCell;
