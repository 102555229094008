import { useTranslation } from '@/app/i18n/client';
import { HomepageContentType } from '@/types/json-content/homepage';
import { Heading, ScrollableTabs } from '@purposeinplay/core-v2';
import React, { useMemo, useState } from 'react';
import RecentWinners from './recent-winners';
import useMediaQuery, { XS_BREAKPOINT } from '@/hooks/use-media-query';
import { cn } from '@/utils/style';
import dynamic from 'next/dynamic';
import { TableLoader } from '../Loaders';

const BigWinners = dynamic(
  () => import('./big-winners.tsx').then((module) => module.default),
  {
    ssr: false,
    loading: () => <TableLoader />,
  },
);
const Leaderboard = () => {
  const { t } = useTranslation();
  const content = t('homepage') as HomepageContentType;
  const { matchesQuery: isXS } = useMediaQuery(XS_BREAKPOINT);

  const [items, setItems] = useState(content?.leaderboard?.tabs);

  const handleTabClick = (e: any, item: any) => {
    setItems((prevItems) =>
      prevItems?.map((el) => ({
        ...el,
        active: el.id === item.id ? true : false,
      })),
    );
  };

  const activeContent = useMemo(() => {
    const activeElement = items?.find((item) => item?.active);
    if (!activeElement) return null;
    if (activeElement?.id === 'casino-wins') {
      return <RecentWinners />;
    } else {
      return <BigWinners />;
    }
  }, [items]);

  return (
    <div className="relative">
      <div
        className={cn([
          'mb-spacing-xs flex flex-row items-center justify-between md:mb-7',
          isXS && 'flex-col items-start space-y-3',
        ])}
      >
        <Heading
          as="h2"
          className="max-w-[10rem] truncate text-xl font-bold xs:max-w-none"
        >
          {content?.leaderboard?.title}
        </Heading>
        <ScrollableTabs
          defaultSelectedIndex={0}
          items={items}
          onClick={handleTabClick}
          variant="segmented-rounded"
          tabStyleOptions={{
            containerStyles: 'hide-scroll-wrapper ',
            navStyles: 'hide-scroll-wrapper',
            navItemStyles:
              'focus:!outline-none focus:!border-0 whitespace-nowrap',
          }}
        />
      </div>
      {activeContent}
    </div>
  );
};

export default Leaderboard;
