import { TableRow } from '@purposeinplay/core-v2';
import { useRouter } from 'next/navigation';
import React from 'react';
// @ts-ignore
import getSlug from 'speakingurl';
import WinnerRow from './winner-row';
import { cn } from '@/utils/style';

const RowWrapper = React.memo(
  ({
    winner,
    imageUrl,
    index,
  }: {
    imageUrl: string;
    winner: any;
    index?: number;
  }) => {
    const game = winner?.game;
    const router = useRouter();

    return (
      <TableRow
        onRowSelect={() => {
          router.push(`/games/${game?.seo_title}`);
        }}
        className={cn([
          `animated-row ${index === 0 ? 'first-child' : ''} cursor-pointer `,
        ])}
      >
        <WinnerRow imageUrl={imageUrl} winner={winner} />
      </TableRow>
    );
  },
);

RowWrapper.displayName = 'LeaderboardRow';

export default RowWrapper;
