import React from 'react';
import ProvidersCarousel from '@/components/Carousels/providers-carousel';
import { GameProvider } from '@/types/games';
import { ProviderCarouselLoader } from '@/components/Loaders';

const HomeProviders: React.FC<{
  providers: GameProvider[];
}> = React.memo(({ providers }) => {
  return providers?.length > 0 ? (
    <div className="relative">
      <ProvidersCarousel items={providers as any} />
    </div>
  ) : (
    <ProviderCarouselLoader />
  );
});

HomeProviders.displayName = 'HomeProviders';

export default HomeProviders;
