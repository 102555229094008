import React from 'react';
import EmptySportSVG from 'public/assets/empty-states/bullseye.svg';
import { Text } from '@purposeinplay/core-v2';
import Image from 'next/image';
import Link from 'next/link';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';

const SportsItem: React.FC<{ href: string; title?: string; icon?: string }> =
  React.memo(({ title, icon, href }) => {
    return (
      <Link href={href}>
        <div className="mb-spacing-xs flex h-[78px] w-[78px] items-center justify-center rounded-xl bg-bgr-lighter p-7 hover:bg-surface-hovered">
          <div className="relative flex h-[1.75rem] w-[1.75rem] items-center justify-center">
            {icon ? (
              <Image
                src={icon}
                alt={`Sports ${title}`}
                fill
                sizes="(max-width: 768px) 33vw, (max-width: 1200px) 33vw, 33vw"
                loader={DEFAULT_IMAGE_LOADER}
              />
            ) : (
              <EmptySportSVG />
            )}
          </div>
        </div>
        <Text size="xs" className="w-[78px] truncate text-center">
          {title}
        </Text>
      </Link>
    );
  });

SportsItem.displayName = 'SportsItem';

export default SportsItem;
