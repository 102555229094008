import React, { useMemo } from 'react';
import { cn } from '@/utils/style';
import { Table } from '@purposeinplay/core-v2';
import RowWrapper from './row-wrapper';
import { TableLoader } from '../Loaders';
import { useTranslation } from '@/app/i18n/client';
import {
  HomepageContentType,
  TableHeadings,
} from '@/types/json-content/homepage';
import './styles/winner.css';
import { useLiveWinners } from '@/hooks/use-live-winners';
import { usePreloadedImages } from '@/hooks/use-preload-images';
import { LiveWinner, NewWinner } from '@/types/winners';
import { usePathname } from 'next/navigation';
import { FALLBACK_SRC_BASE } from '../GameCard/constants';

const getImageUrl = (winner: LiveWinner | NewWinner) =>
  `https://cdn.wild.io/thumbnail/${winner?.game?.identifier}.png`;

const getFallbackUrl = (winner: LiveWinner | NewWinner) =>
  `${FALLBACK_SRC_BASE}/thumbnail/${winner?.game?.identifier.split('/')[0]}/${winner?.game?.identifier.split('/')[1]}.webp`;

const RecentWinners = React.memo(() => {
  const pathname = usePathname();
  const onGamePage = pathname.includes('games/');
  const { t } = useTranslation();
  const content = t('homepage') as HomepageContentType;
  const order: (keyof TableHeadings)[] = [
    'game',
    'player',
    'bet_amount',
    'multiplier',
    'payout',
  ];

  const leaderboardHeadings = order.map(
    (key) => content?.leaderboard?.table_headings?.[key],
  ) as (string | undefined)[];

  const { winners, isLoading } = useLiveWinners();

  const images = usePreloadedImages(winners, getImageUrl, getFallbackUrl);

  const tableRows = useMemo(() => {
    const usedKeys: { [key: string]: boolean } = {};

    const uniqueWinners = winners.filter((winner: any) => {
      const key = `${winner?.round?.bet ?? winner?.bet_amount_cents}-${winner?.round?.win ?? winner?.win_amount_cents}`;
      if (usedKeys[key]) {
        return false;
      }
      usedKeys[key] = true;
      return true;
    });
    return uniqueWinners.map((winner: any, index) => {
      const foundImage = images?.find(
        (item) => item.identifier === winner?.game?.identifier,
      );
      return (
        <RowWrapper
          imageUrl={foundImage?.imageUrl ?? ''}
          index={index}
          winner={winner}
          key={`${winner?.round?.bet ?? winner?.bet_amount_cents}-${winner?.round?.win ?? winner?.win_amount_cents}-${index}`}
        />
      );
    });
  }, [images, winners]);

  if (isLoading) {
    return <TableLoader />;
  }

  return (
    <div
      className={cn([
        'relative  h-[26rem] overflow-hidden md:h-[25rem]',
        onGamePage && `mb-0 mt-4`,
      ])}
    >
      <Table
        headers={leaderboardHeadings}
        variant="condensed"
        stylingOptions={{
          tableStyles: '',
          containerStyles: 'p-0',
          headerStyles:
            'bg-[#edf0f2] dark:bg-[#0a1c2d] relative z-[50] uppercase whitespace-nowrap',
          tableHeadCellStyles:
            'hidden first-of-type:table-cell last-of-type:table-cell md:table-cell',
          tableBodyStyles: '[&>tr>td]:md:h-[48px]',
        }}
      >
        {tableRows}
      </Table>
    </div>
  );
});

RecentWinners.displayName = 'RecentWinners';

export default RecentWinners;
