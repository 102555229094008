import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import React from 'react';
import client from '@/utils/api';
import { Snackbar } from '@purposeinplay/core-v2';
import { useToast } from '@purposeinplay/utils';
import { useTranslation } from '@/app/i18n/client';
import { NotificationsContentType } from '@/types/json-content/notifications';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';

const useCountries = (): UseQueryResult => {
  return useQuery({
    queryKey: ['playerFields'],
    queryFn: () => client('info/countries'),
  });
};

interface CryptoQuote {
  USD: {
    price: number;
  };
}

interface CryptoRate {
  exchange_rate: number;
  fee: number;
  total_exchange_rate: number;
  symbol: string;
  quote: CryptoQuote;
}

const useCryptoRates = (
  options?: Omit<
    UseQueryOptions<CryptoRate[], any, CryptoRate[], any>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
) => {
  return useQuery<CryptoRate[]>({
    queryKey: ['cryptoRates'],
    queryFn: () =>
      client('info/crypto_exchange_rates').then((data) => {
        const usdRates = data['USD'];
        const results: CryptoRate[] = [];
        Object.keys(usdRates).map((currency) =>
          results.push({
            ...usdRates[currency],
            symbol: currency,
            quote: {
              USD: {
                price: usdRates[currency].total_exchange_rate,
              },
            },
          }),
        );
        return results;
      }),
    retry: false,
    ...options,
  });
};

const useSubscriptions = (): UseQueryResult => {
  return useQuery({
    queryKey: ['playerSubscriptions'],
    queryFn: () => client('subscriptions'),
  });
};

const useUpdateSubscriptions = (displayToast = true): UseMutationResult => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => {
      try {
        const payload = { subscription_params: data };
        return client('subscriptions', payload, 'PUT');
      } catch (e) {
        throw e;
      }
    },
    onSuccess: () => {
      displayToast &&
        toast({
          state: 'success',
          title: toastMessages?.subscriptions_updated,
        });
      return queryClient.invalidateQueries({
        queryKey: ['playerSubscriptions'],
      });
    },
  });
};

const usePlayerStats = (): UseQueryResult<{
  bets_sum: any;
  cashouts_sum: any;
  deposits_count: number;
  deposits_sum: any;
}> => {
  return useQuery({
    queryKey: ['playerStats'],
    queryFn: () => client('player/stats'),
  });
};

export {
  useCountries,
  useSubscriptions,
  useUpdateSubscriptions,
  usePlayerStats,
  useCryptoRates,
};
