import useFiatFromCents from '@/hooks/fiat';
import { useAppSelector } from '@/hooks/redux';
import { useFormattedMoney } from '@/components/WinnersLeaderboard/utils';

export const useCurrencyAndProfit = (
  currencyCode: string,
  betAmount: number,
  winAmount: number,
) => {
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
  const { formattedWinAmount, formattedBetAmount, profitAmount } =
    useFormattedMoney(
      { currency: currencyCode, bet: betAmount, win: winAmount },
      currencyCode as string,
    );

  const fiatBet = useFiatFromCents(currencyCode, betAmount);
  const fiatProfit = useFiatFromCents(currencyCode, winAmount);

  return {
    formattedWinAmount,
    formattedBetAmount,
    profitAmount,
    fiatBet,
    fiatProfit,
    viewFiat,
    currency: currencyCode,
  };
};
