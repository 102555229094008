import { LiveWinner, NewWinner } from '@/types/winners';
import { useLayoutEffect, useState } from 'react';

export const usePreloadedImages = (
  winners: LiveWinner[] & NewWinner[],
  getImageUrl: any,
  getFallbackUrl: any,
) => {
  const [images, setImages] = useState<
    Array<{ imageUrl: string; identifier: string }>
  >([]);

  useLayoutEffect(() => {
    if (winners.length > 0) {
      const loadImages = async () => {
        const imageDetails = await Promise.all(
          winners.map(async (winner) => {
            const imageUrl = await preloadImageWithFallback(
              getImageUrl(winner),
              getFallbackUrl(winner),
            );
            return { imageUrl, identifier: winner.game.identifier };
          }),
        );
        setImages(imageDetails);
      };

      loadImages();
    }
  }, [winners, getImageUrl, getFallbackUrl]);

  return images;
};

export const usePreloadedImage = (
  winner: LiveWinner & NewWinner,
  getImageUrl: any,
  getFallbackUrl: any,
) => {
  const [imageUrl, setImageUrl] = useState('');

  useLayoutEffect(() => {
    async function loadImage() {
      const url = await preloadImageWithFallback(
        getImageUrl(winner),
        getFallbackUrl(winner),
      );
      setImageUrl(url);
    }

    if (winner) {
      loadImage();
    }
  }, [winner, getImageUrl, getFallbackUrl]);

  return imageUrl;
};

const preloadImageWithFallback = async (
  primaryUrl: string,
  fallbackUrl: string,
) => {
  return new Promise<string>((resolve) => {
    const img = new Image();
    img.onload = () => resolve(primaryUrl);
    img.onerror = () => resolve(fallbackUrl);
    img.src = primaryUrl;
  });
};
