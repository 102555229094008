import { useTranslation } from '@/app/i18n/client';
import { HomepageContentType } from '@/types/json-content/homepage';
import React, { useMemo } from 'react';
import SportsItem from './sports-item';
import CarouselWithGradient from '@/components/Carousels/components/carousel-with-gradient';

const Sports = () => {
  const { t } = useTranslation();
  const content = t('homepage') as HomepageContentType;
  const sportsItems = content?.sports?.items;

  const slides = useMemo(() => {
    if (!sportsItems) return [];
    return sportsItems?.map((item) => {
      return (
        <SportsItem
          key={item?.title}
          href={item?.href}
          title={item?.title}
          icon={`/assets/sports-icons/${item?.icon}`}
        />
      );
    });
  }, [sportsItems]);

  return (
    <div>
      <CarouselWithGradient
        options={{
          dragFree: true,
        }}
        space={'8'}
        leftBtnStyles="-mt-3"
        rightBtnStyles="-mt-3"
        showArrows
        items={slides}
        slideWidth={78}
      />
    </div>
  );
};

export default Sports;
