import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import { useMemo } from 'react';

export const formatWinner = (winner: any) => {
  if (!winner?.game?.title) {
    const regex = /\/\d+$/; // matches a forward slash followed by one or more digits at the end of the string
    const match = winner?.game_table_url.match(regex);
    const currencyId = match ? match[0].substring(1) : null;
    return {
      game: {
        title: winner?.game_title,
        identifier: winner.game_identifier,
        provider: winner?.game_identifier?.split('/')[0],
        currencies: {
          [winner?.currency]: currencyId,
        },
      },
      round: {
        currency: winner && winner.currency,
        bet: winner.bet_amount_cents,
        win: winner.win_amount_cents,
      },
      player: { nickname: winner.nickname },
    };
  } else {
    return winner;
  }
};

interface Round {
  win: number;
  bet: number;
  currency: string;
}

export const useFormattedMoney = (round: Round, currency: string) => {
  const { formatCurrency } = useCurrencyFormatter();
  return useMemo(() => {
    const winAmount = round?.win;
    const formattedWinAmount = formatCurrency(winAmount, currency, {
      style: 'decimal',
    });
    const betAmount = round?.bet;
    const formattedBetAmount = formatCurrency(betAmount, round?.currency, {
      style: 'decimal',
    });

    const profitAmount = winAmount - betAmount;

    const formattedProfitAmount = formatCurrency(profitAmount, currency, {
      style: 'decimal',
    });

    return {
      profitAmount,
      formattedWinAmount,
      formattedBetAmount,
      formattedProfitAmount,
    };
  }, [round?.win, round?.bet, round?.currency, formatCurrency, currency]);
};
