import React, { useMemo } from 'react';
import Link from 'next/link';
import { Carousel } from '@purposeinplay/core-v2';
import ProviderCard from '@/modules/providers/components/providers-card';
import CarouselWithGradient from './components/carousel-with-gradient';
import { Collection } from '@/types/games';

interface Provider {
  id: string;
  title: string;
}

interface Props {
  items: Provider[];
}

const ProvidersCarousel: React.FC<Props> = React.memo(({ items }) => {
  const slides = useMemo(() => {
    return items.map((provider) => (
      <div key={provider.id}>
        <Link prefetch={false} href={`/providers/${provider.id}`}>
          <ProviderCard provider={provider as Collection} />
        </Link>
      </div>
    ));
  }, [items]);

  return (
    <CarouselWithGradient
      items={slides}
      space="12"
      showArrows
      horizontalEdgeGap={false}
      slideWidth={132}
    />
  );
});

ProvidersCarousel.displayName = 'ProvidersCarousel';

export default ProvidersCarousel;
