import useDarkMode from '@/hooks/dark';
import { cn } from '@/utils/style';
import { Badge } from '@purposeinplay/core-v2';
import Link from 'next/link';
import React from 'react';
import { TextLoader } from '../Loaders';

const BadgeLink = ({
  text,
  gamesCount,
  href,
  isLoading,
  className,
  badgeProps,
  disableLink,
  onClick,
}: {
  text: string;
  gamesCount: number;
  href: string;
  isLoading?: boolean;
  className?: string;
  disableLink?: boolean;
  badgeProps?: {
    size?: 'sm' | 'md' | 'lg';
    className?: string;
  };
  onClick?: () => void;
}) => {
  const { darkMode } = useDarkMode();

  if (isLoading) {
    return <TextLoader className="h-10 w-20" />;
  }

  if (gamesCount === undefined || gamesCount === null) {
    // Empty div to prevent layout shift
    return <div className="h-10 w-20" />;
  }

  return (
    <Link
      onClick={(e) => {
        if (disableLink) {
          e.preventDefault();
        }
        if (onClick) {
          onClick();
        }
      }}
      href={href}
    >
      <div
        tabIndex={0}
        className={cn([
          'link-button--tertiaryGhost link-button  flex flex-row gap-1 whitespace-nowrap',
          darkMode ? 'hover-bg-dark-opacity-50' : 'hover-bg-light-opacity-50',
          className,
        ])}
      >
        <span>{text}</span>
        <Badge
          type="success"
          size={badgeProps?.size || 'md'}
          radius="rounded"
          className={badgeProps?.className}
        >
          {gamesCount}
        </Badge>
      </div>
    </Link>
  );
};

export default BadgeLink;
