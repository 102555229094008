import { useAppSelector } from '@/hooks/redux';
import { getIconByCurrency } from '@/utils/helpers';
import { Text } from '@purposeinplay/core-v2';
import React from 'react';
import { TableCell } from '@purposeinplay/core-v2';
import { cn } from '@/utils/style';

const BetAmountCell = React.memo(
  ({
    formattedBetAmount,
    fiatBet,
    currency,
    className,
  }: {
    formattedBetAmount: string;
    fiatBet: React.ReactNode;
    currency: any;
    className?: string;
  }) => {
    const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
    return (
      <TableCell
        className={cn(['w-auto overflow-hidden', className && className])}
        variant="condensed"
      >
        <div className="min-w-40">
          {!viewFiat ? (
            <div className="flex flex-row items-center">
              <Text size="sm" className="mr-1 leading-4 text-text-subdued">
                {formattedBetAmount.split(' ')[0]}
              </Text>
              {getIconByCurrency(currency)}
            </div>
          ) : (
            <span className="flex items-center font-normal">
              <span className="mr-1.5">{fiatBet}</span>
              <span>{getIconByCurrency(currency)}</span>
            </span>
          )}
        </div>
      </TableCell>
    );
  },
);

BetAmountCell.displayName = 'BetAmountCell';

export default BetAmountCell;
