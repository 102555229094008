import { Button, Heading, IconButton, Text } from '@purposeinplay/core-v2';
import React, { useEffect, useState } from 'react';
import dayjs from '@/utils/dayjsconfig';
import Timeleft from '@/components/Timeleft';
import ArrowSvg from 'public/assets/arrow-chat.svg';
import Link from 'next/link';
import { useTranslation } from '@/app/i18n/client';
import { HomepageContentType } from '@/types/json-content/homepage';
import Image from 'next/image';

export const calculateDate = (
  deadlineHour: number,
  deadlineMinute: number,
  deadlineSecond: number,
) => {
  // Set the current time
  const currentTime = dayjs().utc();

  // Set the deadline date to the same date as the current time
  let deadlineDate = dayjs()
    .utc()
    .set('hour', deadlineHour)
    .set('minute', deadlineMinute)
    .set('second', deadlineSecond);

  // If the deadline time has already passed, add a day to the deadline date
  if (currentTime.isAfter(deadlineDate)) {
    deadlineDate = deadlineDate.add(1, 'day');
  }

  // Calculate the remaining time until the deadline
  const remainingTime = deadlineDate.diff(currentTime, 'millisecond');

  return {
    hours: dayjs.duration(remainingTime).hours(),
    minutes: dayjs.duration(remainingTime).minutes(),
    seconds: dayjs.duration(remainingTime).seconds(),
  };
};

const nextDay = dayjs().utc().add(1, 'day').startOf('day');

const TimeCountdown = React.memo(() => {
  const date = dayjs(nextDay, 'HH:mm:ss');

  const [timeLeft, setTimeLeft] = useState<any>(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateDate(date.hour(), date.minute(), date.second()));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [date]);

  return (
    <Timeleft
      hasDays={false}
      stylingOptions={{
        unit: 'bg-bgr-lightest',
        unitLabel: 'text-text-default',
        dot: 'text-[transparent]',
      }}
      units={{
        minutes: timeLeft?.minutes,
        hours: timeLeft?.hours,
        seconds: timeLeft?.seconds,
      }}
      className="md:mr-8"
    />
  );
});

TimeCountdown.displayName = 'RakebackCounter';

const RakebackBanner = () => {
  const { t } = useTranslation();
  const homepageContent = t('homepage') as HomepageContentType;
  const content = homepageContent?.rakeback_banner;

  return (
    <div className="relative flex flex-row items-center justify-between overflow-hidden rounded-2xl bg-gradient-to-r from-[#C8C6EE] from-[9.16%] via-[#E7E7E7] via-[55.17%] to-[#7C53A7] to-[100%] p-3 pr-0 md:px-7 md:py-spacing-sm dark:from-[#5C59B9] dark:from-[9%] dark:via-[#112333] dark:via-[55%] dark:to-[#7C53A7] dark:to-[100%]">
      <div className="z-10 flex w-full flex-col items-start md:w-auto md:flex-1 md:flex-row md:items-center md:justify-between">
        <div className="mb-spacing-sm flex flex-col md:mb-0 lg:flex-row-reverse lg:justify-between">
          <Text
            as="span"
            className="text-2xl font-bold text-base-secondary md:mb-0.5 md:text-3xl md:font-bold md:leading-9 lg:absolute lg:left-1/2 lg:top-1/2 lg:mb-0 lg:-translate-x-1/2 lg:-translate-y-1/2 lg:transform-gpu lg:pr-[8%] lg:text-center lg:text-3xl xl:pr-0 xl:text-4xl dark:text-text-default"
          >
            {content?.floating_text}
          </Text>
          <div className="flex flex-row lg:flex-col">
            <Text
              size="base"
              className="mr-1 text-sm font-semibold text-base-primary md:text-base lg:mb-1 lg:mr-0"
            >
              {content?.heading?.primary}
            </Text>
            <Text
              size="base"
              className="text-sm font-semibold text-text-default md:text-base"
            >
              {content?.heading?.secondary}
            </Text>
          </div>
        </div>
        <div className="flex w-full flex-row items-end justify-between space-x-spacing-lg md:w-auto md:items-center md:justify-normal">
          <div>
            <Text size="xs" className="mb-2 uppercase">
              {content?.countdown_label}
            </Text>
            <TimeCountdown />
          </div>
          {content?.action?.href && (
            <Link
              className="link-button link-button--primary relative z-10"
              href={content?.action?.href}
              passHref
            >
              {content?.action?.btn_label}
            </Link>
          )}
        </div>
      </div>

      <div className="pointer-events-none absolute bottom-0 right-0 top-0 w-1/2">
        <Image
          className={`pointer-events-none z-0 object-contain object-bottom !p-0 opacity-50 dark:opacity-100`}
          src={`assets/rakeback-banner.png`}
          priority
          alt="Rakeback overlay"
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
          fill
        />
      </div>
    </div>
  );
};

export default RakebackBanner;
