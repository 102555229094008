import { useState, useEffect, useRef } from 'react';
import { LatestWinner, LiveWinner, NewWinner } from '@/types/winners';
import client from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from './auth';
import { useRealtime } from '@/app/providers/realtime';
// @ts-ignore
import compact from 'lodash.compact';
import { formatWinner } from '@/components/WinnersLeaderboard/utils';

export const useLiveWinnersReversed = () => {
  const [winners, setWinners] = useState<Array<LiveWinner & NewWinner>>([]);
  const initialWinnersSet = useRef(false);
  const throttleTimeout = useRef<NodeJS.Timeout | null>(null);

  const { data: latestWinners = [], isLoading: latestWinnersLoading } =
    useQuery<LatestWinner[]>({
      queryKey: ['latestWinners'],
      queryFn: () => client('stats/winners/latest', { isV2: true }),
    });

  const { user } = useAuth();
  const { isLoading: isRealtimeLoading, client: realtimeClient } =
    useRealtime();

  useEffect(() => {
    if (latestWinners.length && !initialWinnersSet.current) {
      const formattedWinners = compact(latestWinners.map(formatWinner)).slice(
        0,
        20,
      );
      setUniqueWinners(formattedWinners);
      initialWinnersSet.current = true;
    }
  }, [latestWinners]);

  const setUniqueWinners = (newWinners: Array<LiveWinner & NewWinner>) => {
    const keySet = new Set();
    const uniqueWinners = newWinners.filter((winner) => {
      const key = `${winner?.round?.bet ?? winner?.bet_amount_cents}-${winner?.round?.win ?? winner?.win_amount_cents}`;
      if (!keySet.has(key)) {
        keySet.add(key);
        return true;
      }
      return false;
    });
    setWinners(uniqueWinners);
  };

  useEffect(() => {
    if (isRealtimeLoading || user?.isLoading) {
      return;
    }
    if (!realtimeClient) return;

    const handleMessage = (event: any) => {
      const data = JSON.parse(event.data);
      if (data?.body?.channel !== 'public:wins') return;
      const msgData = data.body.data;
      if (msgData && msgData.game) {
        if (!throttleTimeout.current) {
          throttleTimeout.current = setTimeout(() => {
            setWinners((currentWinners) => {
              if (currentWinners.length >= 20) {
                return [...currentWinners.slice(1), msgData]; // Keep the list to 20 items
              }
              return [...currentWinners, msgData];
            });
            throttleTimeout.current = null;
          }, 1000);
        }
      }
    };

    realtimeClient.addEventListener('message', handleMessage);

    return () => {
      if (throttleTimeout.current) {
        clearTimeout(throttleTimeout.current);
      }
      realtimeClient.removeEventListener('message', handleMessage);
    };
  }, [isRealtimeLoading, realtimeClient, user?.isLoading]);

  return { winners, isLoading: latestWinnersLoading };
};

export const useLiveWinners = () => {
  const [winners, setWinners] = useState<LiveWinner[] & NewWinner[]>([]);
  const initialWinnersSet = useRef(false);
  const throttleTimeout = useRef<NodeJS.Timeout | null>(null);

  const { data: latestWinners = [], isLoading: latestWinnersLoading } =
    useQuery<LatestWinner[]>({
      queryKey: ['latestWinners'],
      queryFn: () => client('stats/winners/latest', { isV2: true }),
    });

  const { user } = useAuth();
  const { isLoading: isRealtimeLoading, client: realtimeClient } =
    useRealtime();

  useEffect(() => {
    if (latestWinners?.length && !initialWinnersSet.current) {
      const formattedWinners = compact(latestWinners.map(formatWinner)).slice(
        0,
        20,
      );
      setUniqueWinners(formattedWinners);
      initialWinnersSet.current = true;
    }
  }, [latestWinners]);

  const setUniqueWinners = (newWinners: any) => {
    const uniqueWinners = newWinners.reduce(
      (acc: any, winner: any, index: number) => {
        const key = `${winner?.round?.bet ?? winner?.bet_amount_cents}-${winner?.round?.win ?? winner?.win_amount_cents}-${index}`;
        if (!acc.keys[key]) {
          acc.keys[key] = true;
          acc.data.push(winner);
        }
        return acc;
      },
      { keys: {}, data: [] },
    ).data;

    setWinners(uniqueWinners);
  };

  useEffect(() => {
    if (isRealtimeLoading || user?.isLoading) {
      return;
    }
    if (!realtimeClient) return;

    const handleMessage = (event: any) => {
      const data = JSON.parse(event.data);
      if (data?.body?.channel !== 'public:wins') return;
      const msgData = data.body.data;
      if (msgData && msgData.game) {
        if (!throttleTimeout.current) {
          throttleTimeout.current = setTimeout(() => {
            setWinners((currentWinners) => {
              const newWinners =
                currentWinners.length < 20
                  ? [msgData, ...currentWinners]
                  : [msgData, ...currentWinners.slice(0, 19)];
              return newWinners;
            });
            throttleTimeout.current = null;
          }, 1000);
        }
      }
    };

    realtimeClient.addEventListener('message', handleMessage);

    return () => {
      if (throttleTimeout.current) {
        clearTimeout(throttleTimeout.current);
      }
      realtimeClient.removeEventListener('message', handleMessage);
    };
  }, [isRealtimeLoading, realtimeClient, user?.isLoading]);

  return { winners, isLoading: latestWinnersLoading };
};
