import React from 'react';
import { LiveWinner, NewWinner } from '@/types/winners';
import { useCurrencies } from '@/hooks/currencies';
import HiddenIcon from 'public/assets/hidden.svg';
import { useAppSelector } from '@/hooks/redux';
import { TableCell, Text } from '@purposeinplay/core-v2';
import Image from 'next/image';
import { useCurrencyAndProfit } from './hooks/use-curr-and-profit';
import BetAmountCell from './bet-amount-cell';
import WinAmountCell from './win-amount-cell';
import config from '@/config';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';

interface Props {
  winner: LiveWinner & NewWinner;
  imageUrl: string;
}

const WinnerRow: React.FC<Props> = React.memo(({ winner, imageUrl }) => {
  const { data: currencies } = useCurrencies({
    refetchOnMount: false,
  });
  const winnerCurrency = winner?.currency ?? winner?.round?.currency;

  const currency = currencies.find(
    (currencyItem: any) => currencyItem?.code === winnerCurrency,
  );

  const betAmount = winner?.round?.bet ?? winner?.bet_amount_cents;
  const winAmount = winner?.round?.win ?? winner?.win_amount_cents;

  const {
    formattedWinAmount,
    formattedBetAmount,
    profitAmount,
    fiatBet,
    fiatProfit,
  } = useCurrencyAndProfit(winnerCurrency, betAmount, winAmount);

  const game = winner?.game;
  const multiplier = (Number(winAmount) / Number(betAmount)).toFixed(2);

  const winnerNickname = winner?.player?.nickname ?? winner?.nickname;

  const shouldDisplayNickname =
    winnerNickname && winnerNickname !== '' && winnerNickname !== 'player';

  // Find the first category that intersects with SHOWN_WILD_CATEGORIES, excluding "all"
  const categoryToShow = game?.categories?.find(
    (category) =>
      category !== 'all' && config.SHOWN_WILD_CATEGORIES.includes(category),
  );

  return (
    <>
      <TableCell
        className="max-w-[20ch] truncate !py-1.5 md:max-w-full"
        variant="condensed"
      >
        <div className="flex flex-row items-center space-x-2">
          <div className="relative flex h-7 w-7 items-center justify-center rounded-md">
            {categoryToShow ? (
              <Image
                src={`/assets/home-category-icons/${categoryToShow}.svg`}
                fill
                alt={`Category ${categoryToShow} icon`}
                loader={DEFAULT_IMAGE_LOADER}
              />
            ) : (
              <Image
                src="/assets/home-category-icons/slots.svg"
                fill
                alt="Slots icon"
                loader={DEFAULT_IMAGE_LOADER}
              />
            )}
          </div>
          <div className="flex flex-col">
            <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-none text-text-default">
              {winner.game.title}
            </span>
          </div>
        </div>
      </TableCell>
      <TableCell className="hidden !py-1.5 md:table-cell" variant="condensed">
        <div className="flex w-28 flex-col items-start overflow-hidden text-ellipsis whitespace-nowrap">
          <div className="text-sm leading-4 text-text-default">
            {shouldDisplayNickname ? (
              <>
                {winnerNickname.slice(0, 3)}
                {winnerNickname.slice(3).replace(/./g, '*')}
              </>
            ) : (
              <span className="flex items-center justify-center space-x-1 [&>svg]:fill-current [&>svg]:text-text-default">
                <HiddenIcon />
                <span className="text-text-default">Hidden</span>
              </span>
            )}
          </div>
        </div>
      </TableCell>
      <BetAmountCell
        currency={currency?.code?.toLowerCase()}
        formattedBetAmount={formattedBetAmount}
        fiatBet={fiatBet}
        className="hidden !py-1.5 md:table-cell"
      />
      <TableCell className="hidden !py-1.5 md:table-cell" variant="condensed">
        <div className="flex flex-col">
          <Text size="sm" className="leading-4 text-text-subdued">
            {multiplier}x
          </Text>
        </div>
      </TableCell>
      <WinAmountCell
        profitAmount={profitAmount}
        formattedWinAmount={formattedWinAmount}
        currency={currency?.code?.toLowerCase()}
        fiatProfit={fiatProfit}
        className="!py-1.5"
      />
    </>
  );
});

WinnerRow.displayName = 'WinnerRow';

export default WinnerRow;
